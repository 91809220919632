@import '../vars.scss';
@import '../mixin.scss';

.about {
    display: flex;
    flex-direction : column;
    gap: 3rem;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 3rem 5rem;
        padding: 0 1rem;
        align-items: center;
        gap: 2rem;
        height: 10vh;
    
        @include tablet {
            margin: 0rem 5rem;
            justify-content:start;
        }
        &__arrow {
            width: 150px;

            @include tablet {
                width: auto;
            }
        }
        &__title {
            font-size: 3rem;
            color: $dark-text;
            text-decoration: underline $secondary-color;
            text-underline-offset: 0.75rem;
            opacity: 0;
            animation: fadeIn 2s forwards;
            text-align: center;
            text-shadow: 0.2rem 0.2rem 0px $secondary-color;
            @include tablet {
                text-underline-offset: 1rem;
            }
        }

        img{
            transform: rotate(360deg);
        }
        .slide-in {
            animation: rotateAnimation 1s;
        }
    }

    &__content {
        max-height: 80vh;

        &__principal {
            position: relative;

            &__text {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                background-color: $secondary-color;
                border-radius: 150px 150px 0 0;
                padding: 5rem 1.5rem;
                line-height: 1.9rem;
                color: $dark-text;
                width: 80%;
                margin: auto;
                font-size: 1.5rem;
                text-align: center;
                
                &__line {
                    padding-bottom: 1rem;
                    border-bottom: 3px solid $dark-text;
                }
    
                @include tablet  {
                    padding: 4rem;
                    text-align: start;
                    position: absolute;
                    // bottom: 10%;
                    right: 0;
                    border-radius: 150px 0 0 150px;
                    margin: 0rem 0;
                    width: 70%;
                    line-height: 2rem;
                    font-size: 1.5rem;
                }
            }
        }
       
    }
    
}

@keyframes rotateAnimation {
    from {
        transform: rotate(-500deg);
    }
    to {
        transform: rotate(0deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}