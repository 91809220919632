@import '../vars.scss';
@import '../mixin.scss'; 

.skills {
    color: $dark-text;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header__title {
        display: flex;
        justify-content: center;
        font-size: 3rem;
        text-align: center;
        text-decoration: underline $secondary-color;
        text-underline-offset: 0.75rem;
        margin: 2rem ;
        color: $dark-text;
        text-shadow: 0.2rem 0.2rem 0px $secondary-color;

        @include big {
            margin: 0;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        align-items: center;
        gap: 2rem;
        width: 60%;

        
        @include small {
            width: 75%;
        }

        @include mid {
            width: 80%;
        }

        @include big {
            flex-direction: row;
            margin: 1rem auto;
            width: 90%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 90%;
            align-items: center;
            justify-content: center;
            text-align: start;
            padding: 1rem;
            margin: auto;
            animation: slideInBackground 1s ease-in-out forwards;
            border-radius: 15px;
            -webkit-box-shadow: 16px 16px 0px 2px #FDD443; 
            box-shadow: 16px 16px 0px 2px #FDD443;


            @include big{
                width: 20%;
            }

            &__header {
                width: 100%;
                color: $primary-color;
                font-size: 2rem;
                display: flex; 
                justify-content: center;
                text-align: center;

                &__title {
                    text-align: center;
                }
            }

            &__items {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 1rem;
                color: $primary-color;
                font-size: 1.2rem;
            }
        }
    }
}

 .tool {
      width: calc(50% - 1rem);
      display: flex;
      justify-content: center;
      align-items: center;

      &__text {
        animation: slideInFromTop 1s ease-in-out forwards;
      }

      &__img {
        animation: slideInFromBottom 1s ease-in-out forwards;
      }
    }

.tool_img {
    width: 70px;
    height: 70px;
}

@keyframes slideInFromTop {
    from {
        transform: translateY(-500%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInFromBottom {
    from {
        transform: translateY(500%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInBackground {
    from {
        transform: translateX(100%);
    }
    to {
        background-color: $dark-text;
        transform: translateX(0);
    }
}