@import '../vars.scss';
@import '../mixin.scss';


.contact {
    width: 100%;
    height: 75vh;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include tablet {
        height: 75vh;
    }
    &__form {
        background-color: $dark-text;
        padding: 1rem;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        gap: 2rem;
        color: $primary-color;
        -webkit-box-shadow: 16px 16px 0px 2px #FDD443; 
        box-shadow: 16px 16px 0px 2px $secondary-color;

        @include small {
            width: 80%;
        }

        @include tablet {
            width: 40%;
            padding: 5rem;
        }

        &__title {
            font-size: 2rem;
            opacity: 0; 
            transform: translateX(-100%) translateY(100%);
            animation: fadeInUp 1s ease-out forwards;

            @include small {
                font-size: 3rem;
            }
        }
        &__email, &__message {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
        }

        &__email {
            opacity: 0;
            transform: translateX(100%) translateY(-100%);
            animation: fadeInUp 1s ease-out forwards;
        }

        &__message {
            opacity: 0; 
            transform: translateX(-100%) translateY(100%);
            animation: fadeInUp 1s ease-out forwards;
        }
        &__error {
            color: $secondary-color;
            font-size: 1.25rem;;

        }
        &__button {
            width: 10rem;
            height: 2rem;
            background-color: $primary-color;
            border: none;
            color: $dark-text;
            font-size: 1.25rem;;
            opacity: 0;
            transform: translateX(100%) translateY(-100%);
            animation: fadeInUp 1s ease-out forwards;

            &:hover {
                transition-duration: 0.2s;
                cursor: pointer;
                background-color: $dark-text;
                color: $primary-color;
                border: $primary-color 2px solid;
            }
        }

        label {
            font-size: 1.25rem;;

            @include small {
                font-size: 1.75rem;
            }
        }

        input[type="email"], textarea {
            background-color: $primary-color;
            border: 2px solid $dark-text;
            min-height: 2.5rem;
        }

        textarea {
            max-width: 100%;;
            width: auto;
        }
    }
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateX(0) translateY(0);
    }
}

.sr-only {
    height: px !important;
    overflow: hidden !important;
    position: absolute !important;
    left: -100000000rem;
    width: 0px !important;
}