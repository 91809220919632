@import '../vars.scss';
@import '../mixin.scss';

.portfolio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    align-items: center;
    padding-top: 2rem;

    @include very_big {
        flex-direction: row;
    }

    &__header {
        display: flex;
        width: 150px;
        height: 150px;
        border-radius: 50%; 
        border-bottom: 20px solid $secondary-color;
        border-right: 20px solid $secondary-color; 
        background-color: transparent; 
        justify-content: center;
        align-items: center;

        @include small_tablet{
            width: 350px;
            height: 350px;
        }

        
        &__title {
            color: $dark-text;
            font-size: 3rem;
            text-shadow: 0.2rem 0.2rem 0px $secondary-color;

            @include small_tablet {
                font-size: 4.5rem;
            }
        }
    }

    &__project {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // padding: 2rem 2rem;
            width: 100%;

            @include small_tablet {
                flex-direction: row;
            }
            @include very_big {
                width: calc((100% - 350px) - 2rem - 8rem);
            }
        &__buttons {
            display: none;
            justify-content: space-between;
            width: 100%;
            
            &--prev, &--next {
                display: none;

                @include small_tablet {
                    display: flex
                }
            }

            @include small_tablet {
                display: none
            }
        }

        &__link {
            position: relative;
            width: 90%;
            height: auto;
            border: 7px solid $dark-text;
            text-decoration: none;
            color: $primary-color;
            padding: 2rem 0.2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $dark-text;
            border: solid 5px $secondary-color;
            border-radius: 20px;
            animation:fallAndBounce 1.5s ease-in-out;
            
            @include tablet {
                width: 100%;
                height: 35rem;
                padding: 2rem;
            }

            &__instruction {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #004379c2;
                display: flex;
                flex-direction: column;
                gap: 6rem;
                @include tablet {
                    display: none;
                }

                &__container {
                    padding: 5rem;
                    border: solid 5px $secondary-color;
                    border-radius: 20px;

                    &__icon {
                        scale: 5;
                        animation: scroll 4s ease-in-out infinite;
                    }

                }

                &__text {
                    // border: none;
                    // margin: 0;
                    // padding: 0;
                    height: auto;
                    width: auto;
                
                    &__icon {
                        scale: 4;
                        color: #ca9f11;

                        
                    &:hover {
                        cursor: pointer;
                    }
                    }
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                // padding-bottom: 3rem;;

                &__title {
                    font-size: 2rem;
                    text-align: center;
                    border-top: 4px solid $secondary-color;
                    border-left: 4px solid $secondary-color;
                    border-radius: 15px;
                    line-height: 140%;
                    margin: auto;
                    padding: 1rem;
                    font-weight: 700;

                    @include tablet {
                        font-size: 3rem;
                        line-height: 100%;
                    }

                }
                &__container {
                    display: flex;
                    flex-direction: column;

                    gap: 2.5rem;
                    padding: 0 2rem;

                    @include tablet {
                        flex-direction: row;
                        padding: 1rem;
                    }

                    &__img {
                        max-width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                                    
                        @include tablet {
                            max-width: 40%;
                            justify-content: center;
                        }

                        &__element {
                            max-width: 100%;
                            max-height: 100%;
                            border: 5px solid $secondary-color;
                        }
                    }

                    &__desc {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        align-items: center;
                        max-width: 100%;
                        text-align: center;
                        gap: 2.5rem;

                        @include tablet {
                            max-width: 60%;
                            justify-content: space-between;
                            text-align: start;
                            gap: auto;
                        }

                        &__element {
                            font-size: 1.25rem;
                            line-height: 2rem;

                            @include tablet {
                                font-size: 1.5rem;
                                line-height: 2.5rem;
                            }
                        }

                        &__link {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 3rem;
                            
                            &--many {
                                @include tablet {
                                    justify-content: space-between;
                                }
                            }
    
                            &--one {
                                @include tablet {
                                    justify-content: center;
                                }
                            }

                            
                            &--many, &--one {       
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                margin-bottom: 2rem;
                                // width: 60%;
                                gap: 2rem;

                                @include tablet {
                                    flex-direction: row;
                                    justify-content: space-between;
                                    margin-bottom: 0;
                                }

                            }
                            &__container {

                                &--many, &--one {       
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    // width: 60%;
                                    gap: 2rem;

                                    @include tablet {
                                        flex-direction: row;
                                        justify-content: space-between;
                                        margin-bottom: 0;
                                    }

                                    &__element {
                                        // z-index: -1;
                                        font-size: 1.25rem;
                                        text-decoration: none;
                                        border-radius: 15px;
                                        padding: 0.2rem 1rem;
                                        color: $dark-text;
                                        line-height: 150%;
                                        font-weight: 700;
                                        background: linear-gradient(to left, $secondary-color, $primary-color);
                                        // margin-bottom: 3rem;
                                        text-align: center;

                                        &:hover {
                                            background: $primary-color;
                                            color: $dark-text;
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }

            &__number {
                position: absolute;
                bottom: 1rem;
                display: flex;
                flex-direction: row;
                justify-content: center;

                &__btn {
                    background: none;
                    border: none;
                    margin: 0;
                    padding: 0;
                    font: inherit;
                    color: inherit;
                    cursor: pointer;
                }
                &__element {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #ccc;
                    margin: 0 5px;
                    transition: background-color 0.3s ease;
                    margin: 0 0.75rem;
                    display: none;

                    @include tablet {
                        display: block;
                    }

                    &.active {
                        background-color: $secondary-color;
                    }

                    &--mobile {
                        display: flex;

                        @include tablet {
                            display: none;
                        }
                    }
                }
            }
        }

        &::after {
            @include tablet {
                content: '';
                position: absolute;
                bottom: -55;
                left: 50%;
                transform: translateX(-50%);
                border-width: 100px;
                border-style: solid;
                border-color: transparent transparent $secondary-color transparent;
                z-index: -1;
            }
        }
    }

    .button--nav {
        background: none;
        border: none;
        font-size: 10em;
        color: $secondary-color;
        cursor: pointer;
        padding: 1rem;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}


@keyframes fallAndBounce {
    0% {
        transform: translateY(-100%);
      }
    
      30% {
        transform: translateY(0);
      }
    
      40% {
        transform: translateY(-40px);
      }
    
      50% {
        transform: translateY(0);
      }
    
      60% {
        transform: translateY(-20px);
      }
    
      70% {
        transform: translateY(0);
      }
    }


    
@keyframes scroll {
    0% {
        transform: translateX(0);
      }

    20% {
        transform: translateX(-20);
      }
  
    40% {
        transform: translateX(20);
    }
    
    60% {
        transform: translateX(-20);
    }

    80% {
        transform: translateX(20);
    }

    100% {
        transform: translateX(0);
    }
    }
