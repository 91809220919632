@import "https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Lobster&display=swap";
.notFound svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(4);
}

.notFound svg:first-child {
  z-index: 2;
  fill: #fffcee;
  animation: 1s ease-out 2s forwards move;
}

.notFound svg:nth-child(2) {
  z-index: 1;
  animation: 1s ease-out 2s forwards fillLetter;
  left: 50%;
}

.notFound p {
  -webkit-text-stroke: 4px #fed046;
  text-stroke: 4px #fed046;
  text-align: center;
  color: #0000;
  letter-spacing: .5rem;
  width: 100%;
  font-family: Impact, Haettenschweiler, Arial Narrow Bold, sans-serif;
  font-size: 6rem;
  position: absolute;
  top: 25%;
}

@keyframes strokeLetter {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fillLetter {
  from {
    stroke: 0;
  }

  to {
    stroke: 2px;
    fill: #fffcee;
    transform: translate(-50%, -50%)scale(4);
  }
}

@keyframes move {
  to {
    transform: translate(-60%, -50%)scale(4);
  }
}

.navigation {
  background-color: #fffcee;
}

@media screen and (width >= 1250px) {
  .navigation {
    padding-bottom: 8%;
  }
}

.navigation__container {
  background-color: #fffcee;
  flex-direction: column;
  align-items: center;
  list-style: none;
  display: none;
  position: relative;
}

@media screen and (width >= 1250px) {
  .navigation__container {
    flex-direction: row;
    justify-content: space-between;
    height: 10vh;
    display: flex;
    position: absolute;
    right: 0;
  }
}

.navigation__container__list {
  background-color: #fffcee;
  width: 100%;
}

@media screen and (width >= 1250px) {
  .navigation__container__list {
    justify-content: right;
    width: 100%;
    display: flex;
  }
}

.navigation__container__list__element {
  text-align: center;
  color: #004279;
  border-bottom: 1px solid #004279;
  border-left: 1px solid #fed046;
  align-items: center;
  font-size: 1.75rem;
  text-decoration: none;
  display: flex;
}

@media screen and (width >= 1250px) {
  .navigation__container__list__element {
    border-bottom: none;
    font-size: 1rem;
  }
}

.navigation__container__list__element__link {
  color: #004279;
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 1.5rem 1rem;
  font-size: 1.5rem;
  text-decoration: none;
}

@media screen and (width >= 1250px) {
  .navigation__container__list__element__link {
    justify-content: center;
    align-items: center;
    width: 6rem;
    padding: 1rem 1.2rem;
    font-size: 1.3rem;
    transition: transform .3s;
    display: flex;
  }
}

.navigation__container__list__element__link:hover {
  font-weight: 500;
  transform: scale(1.1);
}

.navigation__container__list__element__networks {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: 50%;
  margin: auto;
  padding: 1.8rem;
  display: flex;
}

@media screen and (width >= 1250px) {
  .navigation__container__list__element__networks {
    background: linear-gradient(to right, #ffb05b, #fbff26);
    border-radius: 0 50px;
    flex-direction: row;
    gap: 1rem;
    width: 100px;
    margin: 0;
    padding: 0 1rem;
  }
}

.container--home {
  width: 70%;
  position: absolute;
  right: 0;
}

.expanded {
  z-index: 1;
  width: 100%;
  display: flex;
  position: absolute;
}

.evitement {
  position: absolute;
  left: -10000000000rem;
}

.evitement:focus {
  color: #fffcee;
  position: static;
}

.img {
  width: 100%;
}

@media screen and (width >= 600px) {
  .img {
    width: 60%;
  }
}

@media screen and (width >= 1250px) {
  .img {
    width: 100%;
  }
}

.icon {
  background-color: #0000;
  border: none;
  width: 75px;
  height: 75px;
  transition: transform 1s;
  display: flex;
  position: relative;
}

@media screen and (width >= 1250px) {
  .icon {
    display: none;
  }
}

.icon span {
  transition-duration: 1s;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.icon span, .icon span:before, .icon span:after {
  background-color: #004279;
  width: 35px;
  height: 2px;
  transition-duration: 1s;
  display: block;
  position: absolute;
}

.icon span:before, .icon span:after {
  content: "";
  transform-origin: 50%;
  transition: transform .5s;
  top: 0;
  left: 0;
}

.icon span:before {
  transform: translateY(-11px);
}

.icon span:after {
  transform: translateY(11px);
}

.icon:hover span:before {
  transform: translateY(-11px);
}

.icon:hover span:after {
  transform: translateY(11px);
}

.svg {
  fill: #0000;
  stroke-width: 1px;
  stroke: #004279;
  stroke-dasharray: 151;
  stroke-dashoffset: 151px;
  transition: stroke-dashoffset 1s;
}

.icon.is-opened {
  transform: rotate(180deg);
}

.icon.is-opened span {
  background-color: #0000;
  transition-duration: 0s;
}

.icon.is-opened span:before {
  transform: translateY(0)rotate(45deg);
}

.icon.is-opened span:after {
  transform: translateY(0)rotate(-45deg);
}

.icon.is-opened svg {
  stroke-dashoffset: 0;
  transition-duration: 1s;
  transform: rotate(-180deg);
}

.about {
  flex-direction: column;
  gap: 3rem;
  display: flex;
}

.about__header {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 10vh;
  margin: 3rem 5rem;
  padding: 0 1rem;
  display: flex;
}

@media screen and (width >= 1000px) {
  .about__header {
    justify-content: start;
    margin: 0 5rem;
  }
}

.about__header__arrow {
  width: 150px;
}

@media screen and (width >= 1000px) {
  .about__header__arrow {
    width: auto;
  }
}

.about__header__title {
  color: #004279;
  text-underline-offset: .75rem;
  opacity: 0;
  text-align: center;
  text-shadow: .2rem .2rem #fed046;
  font-size: 3rem;
  -webkit-text-decoration: underline #fed046;
  text-decoration: underline #fed046;
  animation: 2s forwards fadeIn;
}

@media screen and (width >= 1000px) {
  .about__header__title {
    text-underline-offset: 1rem;
  }
}

.about__header img {
  transform: rotate(360deg);
}

.about__header .slide-in {
  animation: 1s rotateAnimation;
}

.about__content {
  max-height: 80vh;
}

.about__content__principal {
  position: relative;
}

.about__content__principal__text {
  color: #004279;
  text-align: center;
  background-color: #fed046;
  border-radius: 150px 150px 0 0;
  flex-direction: column;
  gap: 1.5rem;
  width: 80%;
  margin: auto;
  padding: 5rem 1.5rem;
  font-size: 1.5rem;
  line-height: 1.9rem;
  display: flex;
}

.about__content__principal__text__line {
  border-bottom: 3px solid #004279;
  padding-bottom: 1rem;
}

@media screen and (width >= 1000px) {
  .about__content__principal__text {
    text-align: start;
    border-radius: 150px 0 0 150px;
    width: 70%;
    margin: 0 0;
    padding: 4rem;
    font-size: 1.5rem;
    line-height: 2rem;
    position: absolute;
    right: 0;
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(-500deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.portfolio {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding-top: 2rem;
  display: flex;
}

@media screen and (width >= 1400px) {
  .portfolio {
    flex-direction: row;
  }
}

.portfolio__header {
  background-color: #0000;
  border-bottom: 20px solid #fed046;
  border-right: 20px solid #fed046;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  display: flex;
}

@media screen and (width >= 800px) {
  .portfolio__header {
    width: 350px;
    height: 350px;
  }
}

.portfolio__header__title {
  color: #004279;
  text-shadow: .2rem .2rem #fed046;
  font-size: 3rem;
}

@media screen and (width >= 800px) {
  .portfolio__header__title {
    font-size: 4.5rem;
  }
}

.portfolio__project {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

@media screen and (width >= 800px) {
  .portfolio__project {
    flex-direction: row;
  }
}

@media screen and (width >= 1400px) {
  .portfolio__project {
    width: calc(100% - 350px - 10rem);
  }
}

.portfolio__project__buttons {
  justify-content: space-between;
  width: 100%;
  display: none;
}

.portfolio__project__buttons--prev, .portfolio__project__buttons--next {
  display: none;
}

@media screen and (width >= 800px) {
  .portfolio__project__buttons--prev, .portfolio__project__buttons--next {
    display: flex;
  }

  .portfolio__project__buttons {
    display: none;
  }
}

.portfolio__project__link {
  color: #fffcee;
  background-color: #004279;
  border: 5px solid #fed046;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: auto;
  padding: 2rem .2rem;
  text-decoration: none;
  animation: 1.5s ease-in-out fallAndBounce;
  display: flex;
  position: relative;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link {
    width: 100%;
    height: 35rem;
    padding: 2rem;
  }
}

.portfolio__project__link__instruction {
  background-color: #004379c2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  inset: 0;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__instruction {
    display: none;
  }
}

.portfolio__project__link__instruction__container {
  border: 5px solid #fed046;
  border-radius: 20px;
  padding: 5rem;
}

.portfolio__project__link__instruction__container__icon {
  animation: 4s ease-in-out infinite scroll;
  scale: 5;
}

.portfolio__project__link__instruction__text {
  width: auto;
  height: auto;
}

.portfolio__project__link__instruction__text__icon {
  color: #ca9f11;
  scale: 4;
}

.portfolio__project__link__instruction__text__icon:hover {
  cursor: pointer;
}

.portfolio__project__link__content {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.portfolio__project__link__content__title {
  text-align: center;
  border-top: 4px solid #fed046;
  border-left: 4px solid #fed046;
  border-radius: 15px;
  margin: auto;
  padding: 1rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 140%;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__content__title {
    font-size: 3rem;
    line-height: 100%;
  }
}

.portfolio__project__link__content__container {
  flex-direction: column;
  gap: 2.5rem;
  padding: 0 2rem;
  display: flex;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__content__container {
    flex-direction: row;
    padding: 1rem;
  }
}

.portfolio__project__link__content__container__img {
  justify-content: center;
  align-items: center;
  max-width: 100%;
  display: flex;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__content__container__img {
    justify-content: center;
    max-width: 40%;
  }
}

.portfolio__project__link__content__container__img__element {
  border: 5px solid #fed046;
  max-width: 100%;
  max-height: 100%;
}

.portfolio__project__link__content__container__desc {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  max-width: 100%;
  height: 100%;
  display: flex;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__content__container__desc {
    text-align: start;
    justify-content: space-between;
    gap: auto;
    max-width: 60%;
  }
}

.portfolio__project__link__content__container__desc__element {
  font-size: 1.25rem;
  line-height: 2rem;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__content__container__desc__element {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}

.portfolio__project__link__content__container__desc__link {
  flex-wrap: wrap;
  gap: 3rem;
  display: flex;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__content__container__desc__link--many {
    justify-content: space-between;
  }

  .portfolio__project__link__content__container__desc__link--one {
    justify-content: center;
  }
}

.portfolio__project__link__content__container__desc__link--many, .portfolio__project__link__content__container__desc__link--one {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__content__container__desc__link--many, .portfolio__project__link__content__container__desc__link--one {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.portfolio__project__link__content__container__desc__link__container--many, .portfolio__project__link__content__container__desc__link__container--one {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__content__container__desc__link__container--many, .portfolio__project__link__content__container__desc__link__container--one {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.portfolio__project__link__content__container__desc__link__container--many__element, .portfolio__project__link__content__container__desc__link__container--one__element {
  color: #004279;
  text-align: center;
  background: linear-gradient(to left, #fed046, #fffcee);
  border-radius: 15px;
  padding: .2rem 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
}

.portfolio__project__link__content__container__desc__link__container--many__element:hover, .portfolio__project__link__content__container__desc__link__container--one__element:hover {
  color: #004279;
  background: #fffcee;
}

.portfolio__project__link__number {
  flex-direction: row;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.portfolio__project__link__number__btn {
  font: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.portfolio__project__link__number__element {
  background-color: #ccc;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 .75rem;
  transition: background-color .3s;
  display: none;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__number__element {
    display: block;
  }
}

.portfolio__project__link__number__element.active {
  background-color: #fed046;
}

.portfolio__project__link__number__element--mobile {
  display: flex;
}

@media screen and (width >= 1000px) {
  .portfolio__project__link__number__element--mobile {
    display: none;
  }

  .portfolio__project:after {
    content: "";
    z-index: -1;
    border: 100px solid #0000;
    border-bottom-color: #fed046;
    position: absolute;
    bottom: -55px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.portfolio .button--nav {
  color: #fed046;
  cursor: pointer;
  background: none;
  border: none;
  padding: 1rem;
  font-size: 10em;
}

.portfolio .button--nav:disabled {
  cursor: not-allowed;
  opacity: .5;
}

@keyframes fallAndBounce {
  0% {
    transform: translateY(-100%);
  }

  30% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-40px);
  }

  50% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(-20px);
  }

  70% {
    transform: translateY(0);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-20px);
  }

  40% {
    transform: translateX(20px);
  }

  60% {
    transform: translateX(-20px);
  }

  80% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
  }
}

.right {
  flex-direction: column-reverse;
  width: 100%;
  display: flex;
}

@media screen and (width >= 1250px) {
  .right {
    flex-direction: column;
    width: 50%;
    height: 100vh;
  }
}

.presentation {
  color: #004279;
  text-align: center;
  opacity: 0;
  background-color: #fffcee;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;
  font-size: 2rem;
  animation: 1s 1s forwards fadeIn;
  display: none;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media screen and (width >= 1250px) {
  .presentation {
    gap: 2rem;
    height: 70vh;
    margin-top: 10rem;
    font-size: 3rem;
    display: flex;
  }
}

.presentation__name {
  font-size: 3rem;
}

@media screen and (width >= 800px) {
  .presentation__name {
    font-size: 3.5rem;
  }
}

@media screen and (width >= 1250px) {
  .presentation__name {
    font-size: 5rem;
  }
}

.presentation__title {
  margin: auto;
}

@media screen and (width >= 800px) {
  .presentation__title {
    width: 80%;
  }
}

@media screen and (width >= 1250px) {
  .presentation__title {
    width: 100%;
  }
}

.presentation__mouse {
  width: 100%;
  padding: 0 0 3rem;
}

@media screen and (width >= 1250px) {
  .presentation__mouse {
    padding: 0;
  }
}

.left {
  width: 100%;
  height: 100%;
}

@media screen and (width >= 1250px) {
  .left {
    width: 50%;
    height: 100vh;
  }
}

.left__polygon {
  clip-path: polygon(0 0, 0% 0, 0% 0%, 0% 0%);
  background: linear-gradient(to right, #fbff26, #ffb05b);
  align-items: center;
  height: 51vh;
  animation: 1.5s forwards slideIn;
  position: relative;
}

@media screen and (width >= 1250px) {
  .left__polygon {
    height: 100vh;
  }

  .left__polygon:after, .left__polygon:before {
    content: "";
    border-left: 5px solid #004279;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    transform: skew(26.8deg);
  }
}

.left__polygon:after {
  left: 53%;
}

.left__polygon:before {
  left: 60%;
}

@keyframes slideIn {
  to {
    clip-path: polygon(0 0, 53% 0, 100% 100%, 0% 100%);
  }
}

.left__container {
  z-index: 1;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 15vh;
  bottom: 45vh;
  right: 10%;
}

@media screen and (width >= 1250px) {
  .left__container {
    width: 500px;
    height: 500px;
    top: 50%;
    right: 51%;
    transform: translateY(-50%);
  }
}

.left__container__photo {
  max-width: 100%;
}

.mobil {
  color: #004279;
  text-align: center;
  opacity: 0;
  background: linear-gradient(to right, #fbff26, #ffb05b);
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  padding: 1.5rem;
  font-size: 2rem;
  animation: 1s 1s forwards fadeIn;
  display: flex;
}

@media screen and (width >= 800px) {
  .mobil {
    font-size: 2.5rem;
  }
}

@media screen and (width >= 1250px) {
  .mobil {
    display: none;
  }
}

.home {
  background-color: #fffcee;
  flex-direction: column-reverse;
  display: flex;
}

@media screen and (width >= 1250px) {
  .home {
    flex-direction: row;
    height: 100vh;
  }
}

.skills {
  color: #004279;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.skills__header__title {
  text-align: center;
  text-underline-offset: .75rem;
  color: #004279;
  text-shadow: .2rem .2rem #fed046;
  justify-content: center;
  margin: 2rem;
  font-size: 3rem;
  -webkit-text-decoration: underline #fed046;
  text-decoration: underline #fed046;
  display: flex;
}

@media screen and (width >= 1250px) {
  .skills__header__title {
    margin: 0;
  }
}

.skills__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 60%;
  margin: auto;
  display: flex;
}

@media screen and (width >= 380px) {
  .skills__container {
    width: 75%;
  }
}

@media screen and (width >= 600px) {
  .skills__container {
    width: 80%;
  }
}

@media screen and (width >= 1250px) {
  .skills__container {
    flex-direction: row;
    width: 90%;
    margin: 1rem auto;
  }
}

.skills__container__content {
  text-align: start;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 90%;
  margin: auto;
  padding: 1rem;
  animation: 1s ease-in-out forwards slideInBackground;
  display: flex;
  box-shadow: 16px 16px 0 2px #fdd443;
}

@media screen and (width >= 1250px) {
  .skills__container__content {
    width: 20%;
  }
}

.skills__container__content__header {
  color: #fffcee;
  text-align: center;
  justify-content: center;
  width: 100%;
  font-size: 2rem;
  display: flex;
}

.skills__container__content__header__title {
  text-align: center;
}

.skills__container__content__items {
  color: #fffcee;
  flex-flow: wrap;
  gap: 1rem;
  width: 100%;
  font-size: 1.2rem;
  display: flex;
}

.tool {
  justify-content: center;
  align-items: center;
  width: calc(50% - 1rem);
  display: flex;
}

.tool__text {
  animation: 1s ease-in-out forwards slideInFromTop;
}

.tool__img {
  animation: 1s ease-in-out forwards slideInFromBottom;
}

.tool_img {
  width: 70px;
  height: 70px;
}

@keyframes slideInFromTop {
  from {
    opacity: 0;
    transform: translateY(-500%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(500%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInBackground {
  from {
    transform: translateX(100%);
  }

  to {
    background-color: #004279;
    transform: translateX(0);
  }
}

.contact {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75vh;
  margin: auto;
  display: flex;
  overflow: hidden;
}

@media screen and (width >= 1000px) {
  .contact {
    height: 75vh;
  }
}

.contact__form {
  color: #fffcee;
  -webkit-box-shadow: 16px 16px 0 2px #fdd443;
  background-color: #004279;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 70%;
  margin: auto;
  padding: 1rem;
  display: flex;
  box-shadow: 16px 16px 0 2px #fed046;
}

@media screen and (width >= 380px) {
  .contact__form {
    width: 80%;
  }
}

@media screen and (width >= 1000px) {
  .contact__form {
    width: 40%;
    padding: 5rem;
  }
}

.contact__form__title {
  opacity: 0;
  font-size: 2rem;
  animation: 1s ease-out forwards fadeInUp;
  transform: translateX(-100%)translateY(100%);
}

@media screen and (width >= 380px) {
  .contact__form__title {
    font-size: 3rem;
  }
}

.contact__form__email, .contact__form__message {
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  display: flex;
}

.contact__form__email {
  opacity: 0;
  animation: 1s ease-out forwards fadeInUp;
  transform: translateX(100%)translateY(-100%);
}

.contact__form__message {
  opacity: 0;
  animation: 1s ease-out forwards fadeInUp;
  transform: translateX(-100%)translateY(100%);
}

.contact__form__error {
  color: #fed046;
  font-size: 1.25rem;
}

.contact__form__button {
  color: #004279;
  opacity: 0;
  background-color: #fffcee;
  border: none;
  width: 10rem;
  height: 2rem;
  font-size: 1.25rem;
  animation: 1s ease-out forwards fadeInUp;
  transform: translateX(100%)translateY(-100%);
}

.contact__form__button:hover {
  cursor: pointer;
  color: #fffcee;
  background-color: #004279;
  border: 2px solid #fffcee;
  transition-duration: .2s;
}

.contact__form label {
  font-size: 1.25rem;
}

@media screen and (width >= 380px) {
  .contact__form label {
    font-size: 1.75rem;
  }
}

.contact__form input[type="email"], .contact__form textarea {
  background-color: #fffcee;
  border: 2px solid #004279;
  min-height: 2.5rem;
}

.contact__form textarea {
  width: auto;
  max-width: 100%;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateX(0)translateY(0);
  }
}

.sr-only {
  left: -100000000rem;
  height: px !important;
  width: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  background-color: #fffcee;
  font-family: Times New Roman, serif;
}

.currentPage {
  text-underline-offset: .8rem;
  -webkit-text-decoration: underline #fed046;
  text-decoration: underline #fed046;
  text-decoration-thickness: 4px;
  transition-duration: 0s;
}

html {
  font-family: karla;
}

h2 {
  font-family: karla;
  font-weight: 400;
}

h1 {
  font-family: lobster;
  font-weight: 400;
}

/*# sourceMappingURL=index.77d1aa2b.css.map */
