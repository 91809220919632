
@mixin small {
    @media screen and (min-width: 380px){
        @content;
    }
}

@mixin mid {
    @media screen and (min-width: 600px){
        @content;
    }
}

@mixin small_tablet {
    @media screen and (min-width: 800px){
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 1000px){
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: 1024px){
        @content;
    }
}

@mixin big {
    @media screen and (min-width: 1250px){
        @content;
    }
}

@mixin very_big {
    @media screen and (min-width: 1400px){
        @content;
    }
}

