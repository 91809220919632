@import '../vars.scss';
@import '../mixin.scss';

.left {
    width: 100%;
    height: 100%;

    @include big {
        width: 50%;
        height: 100vh;
    }


    &__polygon {
        background: linear-gradient(to right, #fbff26, #ffb05b);
        clip-path: polygon(0 0, 0% 0, 0% 0%, 0% 0%);
        height: 51vh;
        align-items: center;
        animation: slideIn 1.5s forwards; 
        position: relative;

        @include big {
            height: 100vh;
        }
        
        &::after,
        &::before {
            @include big {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 100%;
                border-left: 5px solid $dark-text;
                transform: skew(26.8deg);
            }
        }

        &::after {
            left: 53%;
        }

        &::before {
            left: 60%;
        }
    }

    @keyframes slideIn {
        to {
            clip-path: polygon(0 0, 53% 0, 100% 100%, 0% 100%);
        }
    }

    &__container {
        position: absolute;
        width: 300px;
        height: 300px;
        top: 15vh;
        bottom: 45vh;
        right: 10%;
        z-index: 1;

        @include big {
            width: 500px;
            height: 500px;
            right: 51%;
            top: 50%;
            transform: translateY(-50%);
        }

        &__photo {
            max-width: 100%;
        }
    }
}

.mobil {
    display: flex;
    background: linear-gradient(to right, #fbff26, #ffb05b);
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    color: $dark-text;
    font-size: 2rem;
    text-align: center;
    opacity: 0; 
    animation: fadeIn 1s forwards 1s;
    padding: 1.5rem;

    @include small_tablet {
        font-size: 2.5rem;
    }

    @include big {
        display: none
    }
}