@import '../vars.scss';
@import '../mixin.scss';

.right {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    @include big {
        height: 100vh;
        width: 50%;
        flex-direction: column;
    }
}

